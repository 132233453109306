<template>
	<div id="main-wrapper" class="inner-pages depbonus-page refer-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Refer And Earn</h2>
				<p>The more you share, the more you get</p>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrapper">
				<div class="content-holder wow fadeInLeft col">
					<div class="title-holder">
						<h3>It all starts with sharing!</h3> <br>
						<p>Know someone who you want to introduce to trading? Well, they could make you money. Invite your friends to join AAAFx through your unique referral link. You and your referral shall earn $25 each.
						<br><br>
						Without incurring any extra cost, simply increase your income. The more friends you refer, the more you earn!
						<br><br>
						Always stay on top of your account with our real-time reporting tools and monitor the status of your referrals to maximize your earnings:
						</p>
						<ul class="listing-check">
							<li>Get your unique referral link as and when required.</li>
							<li>See how much you’ve earned till date</li>
							<li>Check referral history</li>
							<li>Access to statistics at all times</li>
						</ul>
					</div>
				</div>
				<div class="img-holder wow fadeInRight col t-center">
					<img src="assets/images/refer-img1.webp" alt="AAAFx" title="AAAFx" width="500" height="428" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper">
				<div class="title-holder">
					<h3>How to get started?</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="icon-holder">
							<img src="assets/images/refer-ico1.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>Log into your Secure Client Area</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="icon-holder">
							<img src="assets/images/refer-ico2.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>Get your referral link and share with your friends.</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="icon-holder">
							<img src="assets/images/refer-ico3.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>Earn $25 as your referrals sign up and qualify.</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
				</div>
				<div class="section benefits-sec">
					<div class="title-holder">
						<h3>What do your referrals have to do?</h3>
						<p>Your referrals simply have to use your referral link to register with us. If they miss doing it, that is not a problem! They can <br>simply come over Live Chat and let us know that they came through you. We will get them added to your Referrals list.  </p>
					</div>
					<div class="benefits-holder col wow fadeInLeft">
						<h3>Benefits to you:</h3>
						<ul class="listing-check listing-check2">
							<li>Unlimited referral earnings.</li>
							<li>After you have referred 10 clients, you can either continue in Refer and Earn plan or switch to the IB program.</li>
							<li>Live tracking of your earnings.</li>
							<li>Attract clients with our lucrative promotional offers including bonus.</li>
							<li>24/5 customer support and Personal account manager.</li>
						</ul>
					</div>
					<div class="benefits-holder bene-refer col wow fadeInRight">
							<h3>Benefits to your referrals:</h3>
							<ul class="listing-check">
								<li>Enjoy our impeccable trading conditions.</li>
								<li>Earn the Referral Reward, just like the referrer.</li>
								<li>Take benefits of our fully integrated accounts with ZuluTrading.</li>
								<li>Take advantage of our promotions, including bonus and Loyalty Program.</li>
								<li>Use their own referral link and bring their own clients.</li>
							</ul>
					</div>
				</div>
				<div class="section ib-program">
					<div class="title-holder wow fadeIn">
						<h3>How is AAAFx Refer and <br>
							Earn Plan different from the IB Program?</h3> <br>
							<p>While both Programs sound similar, they are quite different from each other. Here are the key differences: -</p>
					</div>
					<div class="table-holder wow fadeIn">
						<table>
							<tr>
								<th>FEATURE</th>
								<th>REFER AND EARN PLAN</th>
								<th>IB PROGRAM</th>
							</tr>
							<tr>
								<td>Earnings</td>
								<td>$25 to you and your referral; up to <br>
									10 clients (changes to $20 after <br>
									10 qualified clients)</td>
								<td>20% commissions share in Standard IB <br>Program and customized share in <br>Advanced IB Program</td>
							</tr>
							<tr>
								<td>Earning frequency</td>
								<td>One time reward</td>
								<td>Lifelong commissions</td>
							</tr>
							<tr>
								<td>Live tracking</td>
								<td>Yes</td>
								<td>Yes</td>
							</tr>
							<tr>
								<td>View of referrals' activities</td>
								<td>Limited</td>
								<td>Full - fledged</td>
							</tr>
							<tr>
								<td>Separate Portal</td>
								<td>No</td>
								<td>Yes</td>
							</tr>
							<tr>
								<td>Pay out frequency</td>
								<td>Weekly</td>
								<td>Weekly</td>
							</tr>
						</table>
					</div>
					<div class="title-holder t-left wow fadeIn">
						<p>You can read all the Terms and conditions of the Refer and Earn Plan <a href="#"> here</a></p>
					</div>
					<div class="btn-holder wow fadeIn">
						<a href="#" class="btn btn-yellow">START REFERRING</a>
					</div>
					<div class="title-holder wow fadeIn">
						<h5>Ready to go beyond Refer and Earn Plan?</h5> <br>
						<p>Sign up as an IB with us. Read all benefits <a href="#"> here</a>.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>